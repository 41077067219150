body {
    background-color: #f0f2f5;
}

.cardSaldoTotal {
    display: flex;
    border-radius: 0.5rem;
    padding: 1.25rem;
    font-size: 1.5rem;
    color: white;
    justify-content: center;
}

.cardLabel {
    font-weight: bold;
    margin-right: 1.25rem;
}