.containerMovimentacao {
    height: 100%;
    padding-bottom: 10px;
    border-bottom: solid 1px gray;
}

.containerMovimentacao label {
    margin-left: 10px;
    margin-right: 10px;
}

.containerMovimentacao button {
    margin-left: 10px;
}

.fff {
    background: #fff;
}

.eee {
    background: #eee;
}

.ddd {
    background: #ddd;
}

.Cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 40px 20px 40px;
}

.labelNomeBeneficiario {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    margin-top: 20px;
}

.tableEstabelecimentos {
    margin: 20px 40px 20px 40px;
}

.subtituloMovimentos {
    margin: 20px 40px 0px 40px;
    font-size: 16px;
    font-weight: 600;
}

.red {
    color: red;
}

.green {
    color: green;
}