@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');


.bodyLogin {
    background-image: url('../../assets/login-background.jpg');
    background-size: cover;
    background-position: center center;
    position: absolute;
    width: 100% !important;
    height: 100%;

}


@media only screen and (max-width: 600px) {
    .centerLoginAdmin {
        width: 80% !important;
        height: 45% !important;
    }
}

.centerLoginAdmin {
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 30%;
    height: 70%;
    text-align: center;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}



.titleLoginH1Admin {
    font-size: 36px;
    font-family: 'Poppins-Bold';
    color: white;
}

.titleLoginH6Admin {
    font-size: 18px;
    font-family: 'Poppins-Bold';
    position: relative;
    top: -20px;
    color: white;
}

.login-form-admin {
    max-width: 300px;
    position: relative;
    top: 30%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.login-form-forgot-admin {
    float: right;
}

.login-form-button-admin {
    width: 100%;
}

.EsqueciSenhaAdmin {
    float: right;
    color: white !important;
    text-decoration: underline !important;
}

.bodyLogin .ant-form-explain {
    color: yellow !important;
}