.icon {
    position: relative;
    top: -3px;
}


.btnGerente {
    color: white !important;
    background-color: rgb(96, 177, 76) !important;
}

.btnVendedor {
    color: white !important;
    background-color: rgb(57, 131, 201) !important;
}