@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

@media only screen and (max-width: 600px) {
    .centerLogin {
        width: 80% !important;
        height: 45% !important;
    }
}


.bodyLoginSaldo {
    background-image: url('../../assets/saldo-background.jpg');
    background-size: cover;
    background-position: center center;
    position: absolute;
    width: 100% !important;
    height: 100%;

}

.centerLogin {
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 30%;
    height: 55%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);


}
.titleLoginH1 {
    font-size: 40px;
    font-family: 'Poppins-Bold';
    color: white;
    position: relative;
    top: 30px;
    left: -10px;
}



.login-form {
    max-width: 300px;
    position: relative;
    top: 30%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

.EsqueciSenha {
    float: right;
    color: blue !important;
    text-decoration: underline !important;
}