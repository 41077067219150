.wrapInputTotalValueTelaGrid {
  margin-top: -30px;
  margin-bottom: 10px;
  margin-left: 5px;
  cursor: default;
  border: none;
  border-radius: 3px !important;
  font-weight: bold;
  background-color: transparent;
  color: red;
  display: flex;
  top: -15px;
  position: relative;
}

.wrapInputTotalValue {
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: default;
  border: none;
  border-radius: 3px !important;
  font-weight: bold;
  background-color: transparent;
  color: red;
}

.wrapInputValueTotalValor {
  border: solid 1px;
  border-radius: 3px !important;
  width: 220px;
  padding-left: 5px;
}

.divStepDados {
  position: relative;
  height: 500px;
}

.divStepDadosLabel {
  margin-top: 30px;
}

.divStepDadosCurrency {
  margin-top: 15px;
  float: left;
  width: 50%;
}

.divStepDadosButton {
  margin-top: 10px;
  float: left;
  text-align: left;
  width: 50%;
}

.divStepPeriodo {
  margin-top: 60px;
}


.divStepDados2 {
  overflow: auto;
  display: table;
}

.tableBeneficiariosPedido {
  display: table-row;
}

.btnAnteriorPedido {
  margin-right: 5px;
}

.divRecarregarBeneficiariosPedidoCRUD {
  margin-top: 10px;
  text-align: right;
}

.btnRecarregarBeneficiariosExcluidosPedidoCRUD {
  margin-right: 5px;
}


.btnGerarPedidoComBoleto {
  margin-left: 5px;
}

.divProgress {
  margin-top: 30px;
  width: 100%;
  text-align: center;
}

.avisoLimiteMaior {
  margin-top: 30px;

  text-align: justify;
  text-justify: inter-word;


  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  background-color: #fbf0f0;
  background-image: url(https://storage.googleapis.com/bluve-bucket/idea-2.png);
  background-size: 30px;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  border: solid 1px #db4734;
  border-radius: 6px;
  line-height: 18px;

  padding-top: 10px;
  padding-left: 50px !important;
  padding-right: 10px !important;
  padding-bottom: 10px;


  z-index: 9999999999;
}

.paragrafoAvisoLimiteCreditoTelaInicial {
  position: relative;
  color: black;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 10px;
  left: -10px;
  font-style: italic;
}


.paragrafoAvisoLimiteCredito {
  background-color: #cc3300;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
}

.labelAvisoLimiteCredito {
  position: relative;
  line-height: 1.2;
  top: 3px;
}