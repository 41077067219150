.containerCardsToPrint {
    height: 100%;
    padding-bottom: 10px;
    border-bottom: solid 1px gray;
}


.tableCards {
    margin: 20px 40px 20px 40px;
}

.cardsToPrint {
    margin: 20px 40px 0px 10px;
    font-size: 16px;
    font-weight: 600;
}

.red {
    color: red;
}

.green {
    color: green;
}

.labelConvenios {
    margin-right: 10px;
}

.btnFiltrar {
    margin-left: 10px;
}

.btnNovoCartao {
    color: white !important;
    background-color: #9B619D !important;
    z-index: 999;
    margin-left: 10px;
}

.btnNovoCartao:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
}