@media only screen and (max-width: 600px) {
  body {
    width: 100% !important;
  }

  .divTable {
    width: 100% !important;
  }
}

.ant-table {
  display: block;

  @media screen and (max-width: 600px) {
    &-thead {
      display: none !important;
    }

    &-thead>tr,
    &-tbody>tr {

      th,
      td {
        &:first-of-type {
          padding-top: 1rem !important;
        }

        &:last-of-type {
          padding-bottom: 1rem !important;
        }
      }

      >th,
      >td {
        display: block !important;
        width: auto !important;
        border: none !important;
        padding: 0 1rem !important;
        font-size: 1.1rem !important;

        &:last-child {
          border-bottom: 1px solid #eee !important;
        }
      }
    }
  }
}


.icon {
  position: relative;
  top: -3px;
}

.btnVerPedido:enabled {
  color: white !important;
  background-color: #c175c4 !important;
}

.btnVerPedido:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
}


.btnBoleto:enabled {
  color: white !important;
  background-color: #EC9720 !important;
}

.btnBoleto:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
}

.btnImprimir:enabled {
  color: white !important;
  background-color: #31ec20 !important;
}

.btnImprimir:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
}


.btnFunctions {
  margin-right: 5px;
  margin-bottom: 5px;
}

.btnFunctions .iconBtnFunctions {
  position: relative;
  top: -3.5px;
}

.filtroPedidosConveniado {
  position: absolute;
  float: right;
  text-align: center;
  width: 100%;
  top: 16px
}

.dateTimePedidosConveniado {
  width: 250px;
}

.btnCopyDigLine {
  padding-top: 5px;
  padding-left: 10px;
  height: 25px;
  top: 3px;
  margin-left: 5px;
  width: 35px;
}

.btnOpenBillet {
  margin-left: 2px;
  padding-top: 5px;
  padding-left: 10px;
  height: 25px;
  top: 3px;
  width: 35px;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}