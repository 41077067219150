.imgAvatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
}

.btnSenha {
    color: white !important;
    background-color: #9B619D !important;
}

.btnSenha:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
}

.alphabet {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 15px;
}

.alphabet button {
    margin: 2px;
}

.textError {
    /* color: red;
    display: block;
    width: 400px;
    overflow-wrap: break-word;
    margin-left: 230px;
    text-align: justify;
    text-justify: inter-word;
    top: -30px;
    position: relative; */

    width: 400px;
    background-color: #f0f7fb;
    background-size: 30px;
    background-position: 10px 15px;
    background-repeat: no-repeat;
    border: solid 1px #3498db;
    border-radius: 6px;
    line-height: 18px;
    padding-left: 50px !important;
    padding-right: 1px !important;
    margin-left: 230px;
    position: relative;
    top: -30px;  
}

.searchContainer {
    display: inline-flex;
    flex-direction: column;
}

.btnImpressaoCartao {
    color: white !important;
    background-color: #EC9720 !important;
    margin-right: 10px;
}